'use strict';
import Exception from "app/exception";
import {mostrarError} from "app/mensajes";
import {get} from "app/request";
import {crearElemento,$I} from "app/dom-util";
import MetaMask from "app/web3/metamask"
import t from 'app/traductor';



function enviaForm(firma, direccion) {
  const recordarme = $I("recordarme");
  const data = {"direccion": direccion, "firma": firma};
  if (recordarme && recordarme.checked) {
    data.recordarme = recordarme.value;
  }
  const form = crearElemento("form", {action: '/usuario/login-firma', method: "post"});
  for (const [key, value] of Object.entries(data)) {
    const el = crearElemento("input", {type: 'hidden', name: key, "value": value});
    form.append(el);
  }
  document.body.append(form);
  form.submit();
}

function activarLoginMetamask() {
  const boton = $I("metamask-login");
  if (boton) {
    get('/usuario/login-firma-mensaje').then(result => {
      if (!result.json.mensaje) {
        throw "No se ha recibido el mensaje para firmar";
      }
      boton.removeAttribute("disabled");
      boton.addEventListener("click", async (e) => {
        e.preventDefault();
        try {
          const metaMask = new MetaMask();
          await metaMask.checkChain();
          const direccion = await metaMask.direccion();
          const firma = await metaMask.firmar(result.json.mensaje, direccion);
          enviaForm(firma, direccion);
        } catch (err) {
          if (err instanceof Exception) {
            mostrarError(err.message);
          } else {
            mostrarError(t.__("Ha ocurrido un error"));
          }
        }
      });
    }).catch(() => {
    });
  }
}

export default () => {
  activarLoginMetamask();
};

