'use strict';
export default {
  token : {
    contrato: process.env.BSC_CONTRATO_TOKEN, 
    imagen: window.location.protocol + "//" + window.location.host + '/imagenes/logotipos/logo-color-metamask.png',
    abi: require('./token.json')
  },
  usdt : {
    contrato: process.env.BSC_CONTRATO_USDT,
    imagen: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    abi: require('./usdt.json')
  },
  walletEmpresa: process.env.BSC_WALLET,
  compraUsdtMinimaInicial: process.env.COMPRA_USDT_MINIMA_INICIAL,
  compraUsdtMinima: process.env.COMPRA_USDT_MINIMA,
  chainId: parseInt(process.env.BSC_CHAIN_ID, 16),
  testnet: 0x61,
  mainnet: 0x38
}




