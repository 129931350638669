'use strict';
import Web3 from 'web3';
import t from "app/traductor";
import Exception from "app/exception";
import {post} from "app/request"
import BigNumber from "bignumber.js";
import Config from "app/web3/config"
export default class MetaMask {

  chainIdsPermitidas = [Config.chainId];
  constructor() {
    this.web3 = new Web3(Web3.givenProvider);
    this.web3.eth.givenProvider.once('chainChanged', () => window.location.reload());
    this.web3.eth.givenProvider.once('accountsChanged', () => window.location.reload());
  }
  async direccion() {
    return await this.web3.eth.requestAccounts().then(account => account[0]).catch(() => {
      throw new Exception(t.__("Debes permitir el acceso en metamask"));
    });
  }
  async firmar(mensaje, direccion) {
    this.checkChain();
    return await this.web3.eth.personal.sign(mensaje, direccion, "").catch(() => {
      throw new Exception(t.__("Debes firmar el mensaje para comprobar tu identidad"));
    });
  }
  async chainId() {
    return await this.web3.eth.getChainId();
  }
  async chainValida() {
    const chainId = await this.chainId();//mainnet 0x38, testnet 0x61
    return this.chainIdsPermitidas.includes(chainId);
  }
  async balance(direccion) {
    this.checkChain();
    return await this.web3.eth.getBalance(direccion)
            .then(balance => this.web3.utils.fromWei(balance, "ether"))
            .catch(() => {
              throw new Exception(t.__("Debes permitir el acceso en metamask"));
            });
  }
  async addChain(chainId) {
    try {
      await this.web3.eth.givenProvider.request({
        method: 'wallet_addEthereumChain',
        params: [{chainId: "0x" + (chainId).toString(16), chainName: 'Binance Smart Chain' + (chainId === Config.testnet ? ' - Testnet' : null), rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
            nativeCurrency: {'decimals': 18, 'name': 'BNB', 'symbol': 'BNB'}}],
      });
    } catch (error) {
      console.log(error);
    }
  }
  async cambiarChain(chainId) {// solicitamos el cambio
    try {
      await this.web3.eth.givenProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: "0x" + (chainId).toString(16)}],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await this.addChain(chainId);
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
  async checkChain() {
    if (!await this.chainValida()) {
      await this.cambiarChain(Config.chainId);
      throw new Exception(t.__("Red no soportada"));
    }
  }
  async decimales(token, abi) {
    const contrato = new this.web3.eth.Contract(abi, token);
    return await contrato.methods.decimals().call();
  }
  async pagoToken(from, to, cantidad, token, abi, transactionCallback) {
    await this.checkChain();
    const contrato = new this.web3.eth.Contract(abi, token);

    const decimales = await contrato.methods.decimals().call();
    const cantidadWei = BigNumber(cantidad).times(10 ** decimales);
    const balance = await contrato.methods.balanceOf(from).call()
    const balanceWei = BigNumber(balance);

    if (balanceWei.lt(cantidadWei)) {
      throw new Exception(t.__("No tienes suficientes fondos para realizar la compra"));
    }
    const hash = contrato.methods.transfer(to, cantidadWei.toFixed())
            .send({from: from})
            .once('transactionHash', (hash) => transactionCallback(hash));
  }
  async balanceToken(from, token, abi) {
    await this.checkChain();
    const contrato = new this.web3.eth.Contract(abi, token);

    const decimales = await contrato.methods.decimals().call();
    const balanceWei = await contrato.methods.balanceOf(from).call();
    return BigNumber(balanceWei).div(10 ** decimales);
  }

  async addToken(token, image, abi) {
    await this.checkChain();
    const contrato = new this.web3.eth.Contract(abi, token);
    const nombre = await contrato.methods.symbol().call();
    const decimales = await contrato.methods.decimals().call();
    try {

      await this.web3.eth.givenProvider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token,
            symbol: nombre,
            decimals: decimales,
            image: image,
          },
        },
      });

    } catch (error) {


    }
  }
}


