'use strict';
import {mostrarError, mostrarMensaje} from "app/mensajes";
import {crearElemento, $I} from "app/dom-util";
import MetaMask from "app/web3/metamask"
import t from 'app/traductor';
import {post, get} from "app/request";
import Config from "app/web3/config"
import Exception from "app/exception";
import BigNumber from "bignumber.js";


async function activarCompraToken() {
  const form = $I("comprar-token");
  if (form) {
    form.addEventListener("submit", async (e) => {
      let data = {};
      e.preventDefault();
      try {
        const metaMask = new MetaMask();
        const decimalesUsdt = parseInt(await metaMask.decimales(Config.usdt.contrato, Config.usdt.abi));
        const decimalesToken = parseInt(await metaMask.decimales(Config.token.contrato, Config.token.abi));
        const from = data.from = await metaMask.direccion();
        await get('/panel/wallet').then(result => {
          if (result.json.wallet !== from) {
            throw new Exception(t.__("La wallet utilizada no está registrada, accede con Metamask seleccionando la wallet/cuenta con la que te registraste"));
          }
        });
        const tokens = await get('/panel/tokens').then(result => result.json.tokens);
        const inputCantidad = $I("cantidad-moneda-compra");
        const cantidad = data.cantidad = inputCantidad.value;
        const compraMinima = (BigNumber(tokens??0, decimalesToken).lt("1", decimalesToken) ? Config.compraUsdtMinimaInicial : Config.compraUsdtMinima)
        if (BigNumber(cantidad, decimalesUsdt).lt(compraMinima, decimalesUsdt)) {
          throw new Exception(t.__("La inversión mínima es de " + compraMinima + " USDT"));
        }
        const to = data.to = Config.walletEmpresa;
        const transactionCallback = (hash) => {
          data.hash = hash;
          post("./operacion/compra", {
            hash: hash, origen: from, destino: to, capital: cantidad
          }).then((data) => {
            mostrarMensaje(data.json.mensaje);
          }).catch((e) => {
            data.exception = e;
            mostrarError(t.__("La operación ha sido realizada pero no se ha podido registrar en el sistema, ponte en contacto con nosotros"));
            log("Operacion realizada, no se ha podido registrar", data);
          });
        };
        const j = await metaMask.pagoToken(from, to, cantidad, Config.usdt.contrato, Config.usdt.abi, transactionCallback);
      } catch (err) {
        if (err instanceof Exception) {
          data.exception = err;
          mostrarError(err.message);
          log(err.message, data);
        } else {
          data.exception = err;
          mostrarError(t.__("Ha ocurrido un error"));
          log("Ha ocurrido un error", data);
        }
      }
    });

  }
}
const log = (msg, data) => {
  post('/panel/log', {
    msg: msg, data: data
  });
};

function activarVentaToken() {
  const form = $I("vender-token");
  if (form) {
    form.addEventListener("submit", async (e) => {
      let data = {};
      e.preventDefault();
      try {
        const metaMask = new MetaMask();
        const from = data.from = await metaMask.direccion();
        await get('/panel/wallet').then(result => {
          if (result.json.wallet !== from) {
            throw new Exception(t.__("La wallet utilizada no está registrada, accede con Metamask seleccionando la wallet/cuenta con la que te registraste"));
          }
        });
        const inputCantidad = $I("cantidad-token-venta");
        const cantidad = data.cantidad = inputCantidad.value;

        const to = data.to = Config.walletEmpresa;
        const transactionCallback = (hash) => {
          data.hash = hash;
          post("./operacion/venta", {
            hash: hash, origen: from, destino: to, tokens: cantidad
          }).then((data) => {
            mostrarMensaje(data.json.mensaje);
          }).catch((e) => {
            data.exception = e;
            mostrarError(t.__("La operación ha sido realizada pero no se ha podido registrar en el sistema, ponte en contacto con nosotros"));
            log("Operacion realizada, no se ha podido registrar", data);
          });
        };
        const j = await metaMask.pagoToken(from, to, cantidad, Config.token.contrato, Config.token.abi, transactionCallback);
      } catch (err) {
        if (err instanceof Exception) {
          data.exception = err;
          mostrarError(err.message);
          log(err.message, data);
        } else {
          data.exception = err;
          mostrarError(t.__("Ha ocurrido un error"));
          log("Ha ocurrido un error", data);
        }
      }
    });

  }
}

export default () => {
  activarCompraToken();
  activarVentaToken();
};

