'use strict';
import {mostrarError, mostrarMensaje} from "app/mensajes";
import {crearElemento, $I} from "app/dom-util";
import MetaMask from "app/web3/metamask"
import t from 'app/traductor';
import BigNumber from "bignumber.js";
import Config from "app/web3/config"
import Exception from "app/exception";
import {get} from "app/request";

async function addToMetamask(nombreBoton, configToken){
  const boton = $I(nombreBoton);
  if (boton) {
    boton.addEventListener("click", async (e) => {
      e.preventDefault();
      const metaMask = new MetaMask();
      await metaMask.addToken(configToken.contrato, configToken.imagen, configToken.abi);
    });
  }
}

async function addTokenMetamask() {
  addToMetamask("add-token-metamask", Config.token);
}

async function addUsdtMetamask() {
  addToMetamask("add-usdt-metamask", Config.usdt);
}

async function calcularValorTokens() {
  const valorTokens = $I("valor-tokens-moneda-wallet");
  const precioToken = $I("valor-token-actual");
  const tokens = $I("tokens-wallet");
  const boton = $I("vender-token");
  const fmt = {
    decimalSeparator: ',',
    groupSeparator: '.',
    groupSize: 3,
  };
  if (valorTokens && precioToken && tokens) {
    try {
      const metaMask = new MetaMask();
      const from = await metaMask.direccion();
      await get('/panel/wallet').then(result => {
          if (result.json.wallet !== from) {
            throw new Exception(t.__("La wallet utilizada no está registrada, accede con Metamask seleccionando la wallet/cuenta con la que te registraste"));
          }
        });
      const balance = await metaMask.balanceToken(from, Config.token.contrato, Config.token.abi);
      valorTokens.innerText = balance.times(precioToken.dataset.precio).toFormat(5, fmt);
      tokens.innerText = balance.toFormat(5, fmt);
    } catch (err) {
      if (err instanceof Exception) {
        mostrarError(err.message);
      } else {
        mostrarError(t.__("Ha ocurrido un error al mostrar los tokens del wallet"));
      }
    }
  }
}
export default () => {
  calcularValorTokens();
  addTokenMetamask();
  addUsdtMetamask();
};

